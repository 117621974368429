import React, { PropTypes, Component } from 'react';

import {
    SFInputField,
    CancelButton,
    SaveButton,
    Dialog
} from 'components/index';

class SignatureModal extends Component {
    state = {
        signature: null
    }
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        saveSignature: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired
    };
    componentDidMount() {
        const {
            signature
        } = this.props;
        this.setState({ signature: signature });
    }
    handleCancel = () => this.props.closeModal();

    saveSignature = () => this.props.saveSignature(this.state.signature)

    changeValue = (key, value) => {
        const signature = Object.assign({},  this.state.signature);
        signature[key] = value;
        this.setState({signature});
    };
    render() {
        const { signature } = this.state;
        const { t } = this.props;
        if (!this.state.signature) {
            return <div />;
        }
        return (
            <Dialog variant="edit">
                <div className="signature-edit">
                    <div>
                        <div className="signature-row">
                            <SFInputField
                                defaultInput={true}
                                value={signature.line1}
                                onChange={(event) => this.changeValue('line1', event.target.value)}
                                fieldName={t('Signature line') + ' 1'}
                                placeholder={t("e.g. name")}
                            />
                        </div>
                        <div className="signature-row">
                            <SFInputField
                                defaultInput={true}
                                value={signature.line2}
                                onChange={(event) => this.changeValue('line2', event.target.value)}
                                fieldName={t('Signature line') + ' 2'}
                                placeholder={t("e.g. title")}
                            />
                        </div>
                    </div>
                    <CancelButton cancelAction={this.handleCancel} />
                    <SaveButton
                        onClick={this.saveSignature}
                        disabled={this.props.saving}
                        submitting={this.props.saving}
                    />
                </div>
            </Dialog>
        );
    }
}

export default SignatureModal;
