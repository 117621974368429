import React from 'react';
import translations from 'decorators/translations.js';
import _ from 'lodash';
import {
    CostNumber
}                                       from 'components';
import {
    Card,
    Cards
} from 'components';

import './AgreementContentList.css';

const styles = {
    costNumber: {
        lineHeight: 1,
        verticalAlign: 'baseline'
    }
};

/**
 * Agreement content overview
 */
const AgreementContentList = ({kitpacks, agreement, totalIncome, totalCosts, projectMargin, t, params, selectedCountry}) => {
    const getReadablePriceDetailLevel = (level) => {
        switch(level) {
            case 0:
                return t('price_total_option');
            case 1:
                return t('price_discipline_option');
            case 2:
                return t('price_subdiscipline_option');
            case 3:
                return t('price_system_option');
            default:
                return '';
        }
    };

    const renderIsSet = ({list, field, label}) => {
        const isSet = list ? list.length > 0 : (field && field !== '') ;
        return (
            <div key={label} className="agreement-content-list-row">
                <span><i
                    className={'fa ' + (isSet ? 'fa-check card-body-icon-green' : 'fa-times card-body-icon-red')}/> {label}</span>
            </div>
        );
    };

    let kitpackCount = 0;
    let defaultKitpack = null;
    const agreementKitpacks = _.filter(kitpacks.data, { agreementId: agreement.id });
    if (agreementKitpacks && agreementKitpacks.length > 0) {
        kitpackCount = agreementKitpacks.length;
        defaultKitpack = _.first(agreementKitpacks) || {};
    }
    const basePath = `/client/${params.clientId}/contractInfo/${params.contractInfoId}/agreement/${agreement.id}`;
    if (agreement && agreement.id) {
        return (
            <Cards>
                <Card
                    id="AgreementContentList_SLA"
                    to={`${basePath}/sla`}
                    title={t('SLA')}
                    ctas={[{
                        title: t('Set SLA')
                    }]}
                >

                    { renderIsSet({list: agreement.responseTime, label: t('response_time')}) }
                    { renderIsSet({list: agreement.supportTime247, label: t('support_time')}) }
                </Card>
                {!(selectedCountry && selectedCountry.customizations && selectedCountry.customizations.hideCcc) &&
                    <Card
                        id="AgreementContentList_Collectors"
                        to={`${basePath}/collectors/${ kitpackCount > 0 ? _.get(kitpacks, `data[${agreement.id}][0].id`, '') : '' }`}
                        title={t('CCC')}
                        ctas={[{
                            title: kitpackCount > 0
                            ? t('edit')
                            : t('Add')
                        }]}
                    >
                        {
                            defaultKitpack && defaultKitpack.bundles && defaultKitpack.bundles.map(bundle => {
                                let label = t('collector_bundle_' + bundle.type);
                                if (bundle.type === 'sensor') {
                                    if (bundle.sensors && bundle.sensors.length === 1) {
                                        const sensor = _.head(bundle.sensors);
                                        if (sensor.type) {
                                            label = t('sensor_type_' + sensor.type);
                                        }
                                    }
                                }
                                return <div className="agreement-content-list-row" key={bundle.id}>
                                    { label }
                                </div>;
                            })
                        }
                    </Card>
                }
                <Card
                    id="AgreementContentList_Costs"
                    to={`${basePath}/costs`}
                    title={t('costs')}
                    ctas={[{
                        title: t('adjust_income')
                    }]}
                >
                    <div className="agreement-content-list-row">
                        { t('totalCosts') }: <CostNumber style={ styles.costNumber }>{totalCosts}</CostNumber>
                    </div>
                    <div className="agreement-content-list-row">
                        { t('totalIncome') }: <CostNumber style={ styles.costNumber }>{totalIncome}</CostNumber>
                    </div>
                    <div className="agreement-content-list-row">
                        { t('actualProjectMargin') }: <strong>{projectMargin} %</strong>
                    </div>
                    { agreement.clientNet && agreement.clientNetCustomPrice != null &&
                        <div className="agreement-content-list-row">
                            { t('SmartView income') }: <CostNumber style={ styles.costNumber }>{ agreement.clientNetTotalPrice }</CostNumber>
                        </div>
                    }
                </Card>
                <Card
                    id="AgreementContentList_Pricelist"
                    to={`${basePath}/pricelist`}
                    title={t('pricelist')}
                    ctas={[{
                        title: t('Adjust pricelist')
                    }]}
                >
                    <div className="agreement-content-list-row">{ t('Pricelist detail level') }: <strong>{getReadablePriceDetailLevel(agreement.priceDetailLevel)}</strong></div>
                </Card>
                <Card
                    id="AgreementContentList_Comments"
                    to={`${basePath}/comments`}
                    title={t('comments')}
                    ctas={[{
                        title: t('Add comments')
                    }]}
                >
                    <div className="agreement-content-list-row">{ t('Add comments to the agreement printout') }</div>
                </Card>
                <Card
                    id="AgreementContentList_Contents"
                    to={`${basePath}/contents`}
                    title={t('Contents')}
                    ctas={[{
                        title: t('Set contents')
                    }]}
                >
                    {
                        (agreement.contents && agreement.contents.length > 0) ? <p>{t('Contents set')}</p> : <p>{t('Defaults')}</p>
                    }
                </Card>
                <Card
                    id="AgreementContentList_SmartView"
                    to={`${basePath}/smartview`}
                    title={t('SmartView')}
                    ctas={[{
                        title: t('Add SmartView')
                    }]}
                >
                    {
                        agreement.clientNet ? <p>{t('SmartView added')}</p> : <p>{t('No SmartView')}</p>
                    }
                </Card>
            </Cards>
        )
    }
    return null;
}

export default translations(AgreementContentList);