import React from 'react';
import translations from 'decorators/translations.js';
import {localeDateString} from 'helpers/stringTemplates';
import _ from 'lodash';
import {
    measures,
    colors
} from 'styles/design.js';

const AgreementTimestampList = (props) => {
    const {t, agreement} = props;
    if (!agreement || !agreement.agreementStatus) {
        return (<div/>);
    }
    const isRevoked = Boolean(agreement.agreementStatus === 'draft' && agreement.revokedBy && agreement.revokedTime);
    const isSentForApproval = Boolean(agreement.agreementStatus === 'sent_for_approval');
    const isRejected = Boolean(agreement.agreementStatus === 'rejected');
    const isApproved = Boolean(agreement.approvalTime);
    const isSigned = Boolean(agreement.agreementStatus === 'signed' && agreement.signedTime);
    const isMain = Boolean(agreement.agreementType.match(/main/));
    const isAddon = agreement.mainAgreementId;
    const isFrameAgreement = Boolean(agreement.agreementType === 'frameagreement');
    const isExternalMain = Boolean(agreement.agreementType === 'external_main');
    const isModified = Boolean(!isExternalMain && agreement.created !== agreement.modified);
    const isLost = Boolean(agreement.agreementStatus === 'lost');

    const show = [
        {type: 'created', timeField: 'created', visible: isMain || isFrameAgreement},
        {type: 'modified', timeField: 'modified', visible: (isMain || isAddon || isFrameAgreement) && isModified},
        {type: 'approved', visible: (isMain || isAddon || isFrameAgreement) && isApproved},
        {type: 'rejected', timeField: 'rejectionTime', visible: isRejected},
        {type: 'signed', visible: (isMain || isAddon || isFrameAgreement) && isSigned},
        {type: 'sentForApproval', timeField: 'sentForApprovalTime', field: 'sent_for_approval', visible: isSentForApproval},
        {type: 'revoked', visible: isRevoked},
        {type: 'lost', visible: isLost}
    ];

    const visibles = _.filter(show, (row) => row.visible);
    return (
        <div
            id={props.id}
            style={{ fontSize: '0.8em', color: colors.gray, lineHeight: '1rem' }}
        >
                {
                    visibles.map((row, idx) => {
                        return (<p key={'row_' + idx}>
                            <span>{t(row.field || row.type)} </span>
                            <span>{localeDateString(agreement[row.timeField || row.type+'Time'], {showTimeZone: true})} </span>
                            {row.type === 'created' && <span>{agreement.createdBy}</span>}
                        </p>)
                    })
                }
                {
                    (() => {
                        if (props.showComment && (agreement.agreementStatus === 'rejected' || agreement.agreementStatus === 'approved') && agreement.approverComment) {
                            return (
                                <div style={{
                                    marginTop: measures.double,
                                    paddingLeft: measures.half,
                                    borderLeft: '5px solid ' + colors.orange
                                }}>{agreement.approverComment}</div>
                            );
                        }
                    })()
                }
        </div>
    );
};

export default translations(AgreementTimestampList);
