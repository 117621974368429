import React, { Component }  from 'react';
import translations from 'decorators/translations.js';
import configuredRadium                 from 'configuredRadium.js';
import { connect }                      from 'react-redux';

import _                                from 'lodash';

import {
    commonStyles,
    layout,
    measures,
    colors
}                           from 'styles/design.js';

import {
    SFInputField,
    SaveButton,
    ConfirmableButton,
    CancelButton
} from 'components/index.js';

import {
    loadBillingIndexes,
    saveBillingIndex,
    addBillingIndex,
    deleteBillingIndex
}                               from 'redux/modules/billingIndex';
import {
    loadWithType,
    saveTranslation
}                               from 'redux/modules/translations.js';

class BillingIndexForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexes: [],
            editing: {},
            translated: {},
            defaultIndex: null,
            languages: ['en']
        };
    }
    static propTypes = {
    };
    componentWillMount() {
        const promises = [];
        promises.push(this.props.dispatch(loadBillingIndexes(this.props.profile.accessToken)));
        const languages = ['en'];
        if(this.props.selectedCountry) {
            if(this.props.selectedCountry && this.props.selectedCountry.defaultLanguage && this.props.selectedCountry.defaultLanguage !== 'en') {
                languages.push(this.props.selectedCountry.defaultLanguage);
            }
            promises.push(this.props.loadWithType(2, languages.join(','), this.props.selectedCountry.id, this.props.profile.accessToken));
        }
        return Promise.all(promises).then((res) => {
            if(!_.isEmpty(res)) {
                if(res[0].type === 'ServiFlex/BillingIndex/LOAD_ALL_SUCCESS') {
                    const indexes = this.filterIndexes(res[0].result, this.props.selectedCountry.id);
                    const defaultIndex = _.findIndex(indexes, 'default');
                    this.setState({indexes, defaultIndex});
                }

                if(res[1].type === 'ServiFlex/Translations/LOAD_WITHTYPE_SUCCESS') {
                    this.setState({
                        language: this.props.selectedCountry.defaultLanguage,
                        languages,
                        translated: res[1].result});
                }
            }
        })
    }
    componentWillReceiveProps(nextProps) {
        if(this.state.indexes.length === 0 && nextProps.billingIndexes && nextProps.billingIndexes.length > 0) {
            const indexes = this.filterIndexes(nextProps.billingIndexes, this.props.selectedCountry.id);
            const defaultIndex = _.findIndex(indexes, 'default');
            this.setState({indexes, defaultIndex});
        }
        if(this.props.translations.loading && nextProps.translations.loaded) {
            this.setState({translated: nextProps.translations.translatables});
        }
    }

    setTranslation = (key, value) => {
        const language = this.state.language;
        const translated = this.state.translated;
        if(!translated[key]) {
            translated[key] = {};
        }
        translated[key][language] = value;
        const editing = {key, value};
        this.setState({translated, currentlyEditing: editing});
    };

    filterIndexes(indexes, country) {
        return _.filter(indexes, {countryId: country});
    }

    languageSelected = (event) => {
        this.setState({language: event.target.value});
    };

    deleteIndex = (index) => {
        const billingIndexes = this.state.indexes;
        const row = billingIndexes[index];
        this.props.deleteBillingIndex(row.id, this.props.profile.accessToken).then((res) => {
            _.pullAt(billingIndexes, [index]);
            if(res.result) {
                this.toggleEdit(index);
                this.setState({indexes: billingIndexes});
            }
        });
    };
    addBillingIndex = () => {
        const indexes = this.state.indexes;
        const country = this.props.selectedCountry;
        const highestId = _.maxBy(this.state.indexes, 'id') || {id: 1};
        const newKeyInt = highestId.id + 1;
        const newIndex = {
            key: 'billing_index_' + country.id + '_' + newKeyInt,
            countryId: country.id,
            default: false
        };

        this.props.addBillingIndex(newIndex, this.props.profile.accessToken).then((res) => {
            if(res.result) {
                newIndex.id = res.result.id;
                indexes.push(newIndex);
                this.setState({indexes});
            }
        });
    };
    saveBillingIndex = (idx) => {
        const indexes = this.state.indexes;
        const index = indexes[idx];
        index.countryId = this.props.selectedCountry.id;
        if(!index.id) {
            this.props.addBillingIndex(index, this.props.profile.accessToken);
        } else {
            this.props.saveBillingIndex(index, this.props.profile.accessToken);
        }
        const editing = this.state.currentlyEditing;
        if(editing) {
            const translationData = {
                key: editing.key,
                value: editing.value,
                language: this.state.language,
                country: this.props.selectedCountry.id
            };
            this.props.saveTranslation(translationData, this.props.profile.accessToken);
        }
        this.toggleEdit(idx);
    };
    toggleEdit = (idx) => {
        const editing = this.state.editing;
        if(editing[idx]) {
            editing[idx] = false;
        } else {
            editing[idx] = !editing[idx];
        }
        this.setState({editing});
    };
    toggleDefault = (idx, checked) => {
        const indexes = this.state.indexes;
        indexes[idx].default = checked;
        this.setState({indexes});
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <div style={commonStyles.dataParentDiv}>
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('billing_indexes')}:
                    </div>

                    <div style = {{marginTop: measures.standard, marginBottom: measures.standard}}>

                        <div style = {layout.inlineBlock}>
                            <h3 style = {{textTransform: 'capitalize'}}>{t('Language')}:</h3>
                        </div>
                        <div style = {[layout.inlineBlock, {marginLeft:measures.double}]}>
                            <select style = {commonStyles.select} onChange = {this.languageSelected} value={this.state.language}>
                                {
                                    (() => {
                                        return this.state.languages.map((lang, idx) => {
                                            return (<option key={'lang_options_' + idx} value={lang}>{t(lang)}</option>);
                                        });
                                    })()
                                }
                            </select>
                        </div>
                    </div>
                    {
                        (() => {
                            return this.state.indexes.map((billingIndex, idx) => {
                                const trans = this.state.translated[billingIndex.key];
                                const formProperty = {
                                    value: trans && trans[this.state.language] ? trans[this.state.language] : ''
                                };
                                if(this.state.editing && this.state.editing[idx]) {
                                    return (
                                        <div key={'billing_indx_' + idx} style={{paddingTop: measures.standard, paddingBottom: measures.double, borderBottom: 'thin solid'}}>
                                            <div style={layout.floatRight}>
                                                <ConfirmableButton
                                                    buttonIcon = { 'fa fa-trash' }
                                                    confirmText = { this.props.t('confirm_delete_billingindex') }
                                                    buttonText = { this.props.t('delete') }
                                                    style = {{
                                                        backgroundColor: colors.lightBlue,
                                                        color: colors.white,
                                                        fontSize: '0.75em'
                                                    }}
                                                    action = { () => this.deleteIndex(idx) }

                                                />
                                            </div>
                                            <SFInputField
                                                formProperty={formProperty}
                                                onChangeAction={(event) => this.setTranslation(billingIndex.key, event.target.value)}
                                            />
                                            <div style={{fontSize: '90%'}}>{trans && trans['en-master'] ? '(' + trans['en-master'] + ')' : ''}</div>
                                            <div style={{...layout.floatRight, width: '350px'}}>
                                                <label>
                                                    <input type="checkbox" checked={billingIndex.default} onClick={(event) => this.toggleDefault(idx, event.target.checked)} />
                                                    {t('set_default')}
                                                </label>
                                                <CancelButton cancelAction={() => {this.toggleEdit(idx);}} />
                                                <SaveButton onClick={() => this.saveBillingIndex(idx)} />
                                            </div>
                                            <div style={{clear: 'both'}} />
                                        </div>
                                    );
                                }
                                return (
                                    <div key={'billing_index_' + idx} style={{paddingTop: measures.standard, paddingBottom: measures.standard, borderBottom: 'thin solid'}}>
                                        <div style={layout.floatRight}>
                                            <button type="button" onClick={ () => this.toggleEdit(idx)}
                                                style={{ ...commonStyles.button, backgroundColor: colors.lightBlue, marginTop: '-20px' }}>
                                                <i className="fa fa-pencil" />
                                            </button>
                                        </div>
                                        <div>
                                            { billingIndex.default ? '(' + t('default') + ')' : '' } {(formProperty.value ? formProperty.value.value : formProperty.value) || '-'}
                                        </div>

                                    </div>
                                );

                            });
                        })()
                    }
                    <button type="button"
                        onClick={ this.addBillingIndex}
                        style={{ ...commonStyles.button, backgroundColor: colors.lightBlue, width: '150px', marginTop: measures.standard }}>{ t('add_index') }</button>

                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        billingIndex: state.billingIndex,
        billingIndexes: state.billingIndex.all,
        user: state.userProfile.user,
        profile: state.userProfile,
        translations: state.translations,
        cookies: state.cookies
    };
}
function mapDispatchToProps(dispatch) {
    return {
        saveBillingIndex: (data, token) => {
            return dispatch(saveBillingIndex(data, token));
        },
        addBillingIndex: (data, token) => {
            return dispatch(addBillingIndex(data, token));
        },
        deleteBillingIndex: (id, token) => {
            return dispatch(deleteBillingIndex(id, token));
        },
        saveTranslation: (data, token) => {
            return dispatch(saveTranslation(data, token));
        },
        loadWithType: (type, languages, countryId, accessToken) => dispatch(loadWithType(type, languages, countryId, accessToken))
    };
}
const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(configuredRadium(BillingIndexForm)));
