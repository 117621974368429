import React, {Component}       from 'react';
import translations from 'decorators/translations.js';
import {connect}              from 'react-redux';
import configuredRadium         from 'configuredRadium.js';
import { getLanguage } from 'helpers/languageHelper';
import _ from 'lodash';

import {
    StandardPage,
    BuildingsAndAgreements,
    BreadcrumbBar,
    ClientFrameAgreements,
    Dropdown,
    Accordion,
    AccordionItem,
    AccordionTitle,
    AccordionContent,
    Button
} from 'components';
import { PrintingView } from 'containers';

import {
    layout,
    measures,
    commonStyles
} from 'styles/design.js';

import { loadClient, isClientLoaded }   from 'redux/modules/client.js';
import { loadBuildings } from 'redux/modules/building.js';
import { loadAgreements } from 'redux/modules/agreement.js';
import { loadFrameAgreements} from 'redux/modules/frameagreement.js';
import { loadCountry } from 'redux/modules/country.js';
import {loadAllDepartments} from 'redux/modules/department.js';

import {
    download
}   from 'redux/modules/download.js';

const buttonLayout = [
    layout.floatRight,
    {
        '@media only screen and (max-width: 660px)': {
            display: 'inline-block',
            float: 'none'
        }
    }
];

class ClientOverviewView extends Component {
    componentWillMount() {
        const promises = [],
            params = this.props.match.params,
            token = this.props.profile.accessToken;
        const clientId = parseInt(params.clientId, 10);
        if(!isClientLoaded(this.props, clientId)) {
            promises.push(this.props.dispatch(loadClient(clientId, token)));
        }
        promises.push(this.props.dispatch(loadAllDepartments(token)));
        promises.push(this.props.dispatch(loadBuildings(clientId, token)));
        promises.push(this.props.dispatch(loadAgreements(clientId, token)));
        promises.push(this.props.dispatch(loadFrameAgreements(clientId, token)));
        return Promise.all(promises);
    }
    componentWillReceiveProps(nextProps) {
        const promises = [];
        const clientId = _.get(this.props, 'match.params.clientId', null);
        const token = this.props.profile.accessToken;
        if (this.state.deletingFrameAgreement && nextProps.frameAgreementDeleted) {
            promises.push(this.props.dispatch(loadFrameAgreements(clientId, token)));
            this.setState({deletingFrameAgreement: false});
        }
        if(!isClientLoaded(this.props, clientId) && isClientLoaded(nextProps, clientId)) {

            if(!this.props.countries || !this.props.countries.selectedCountry || this.props.countries.selectedCountry.id !== this.props.client.client.salesArea) {
                const newSalesarea = _.get(nextProps, 'client.client.salesArea');
                if (newSalesarea) {
                    promises.push(this.props.dispatch(loadCountry(newSalesarea, token)));
                }
            }
        }
        return Promise.all(promises);
    }

    downloadFile = (event, downloadType, printAgreementType, printAgreementId, printoutType, printoutLanguage) => {
        event.preventDefault();
        this.openPrintingDialog({ printAgreementType, printAgreementId, printoutType, printoutLanguage });
    };

    createContractInfo = event => {
        event.preventDefault();
        const { client } = this.props;
        const clientId = client && client.client && client.client.id;
        if (clientId) {
            this.props.history.push('/client/' + clientId + '/createContractInfo');
        }
    };

    openPrintingDialog = ({ printoutLanguage, printoutType, printAgreementId, printAgreementType }) => {
        this.setState({
            printoutLanguage: !_.isNil(printoutLanguage) ? printoutLanguage : 'en',
            printoutType: !_.isNil(printoutType) ? printoutType : '',
            printingDialog: true,
            printAgreementId,
            printAgreementType
        });
    };

    closePrintingDialog = () => {
        this.setState({
            printoutLanguage: '',
            printoutType: '',
            printingDialog: false,
            printAgreementId: null,
            printAgremeentType: ''
        });
    };

    renderAgreementAndBuildings = (clientId, info, lang, t) => {
        return <div>
            <div style={{ marginTop: '1em', display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        variant="secondary"
                        href={ '/client/' + clientId + '/contractInfo/' + info.id }
                    >
                        { t('View contract info') }
                    </Button>
                </div>
                <div>
                    <Dropdown
                        id="ClientOverviewView_add"
                        options={[
                            {id: 'ClientOverviewView_addBuilding', name: t('Serviflex agreement'), url: '/client/' + clientId + '/contractInfo/' + info.id + '/createBuilding'},
                            {id: 'ClientOverviewView_addFrameAgreement', name: t('frame_agreement'), url: '/client/' + clientId + '/contractInfo/' + info.id + '/createFrameAgreement'}
                        ]}
                    />
                </div>
            </div>
            <ClientFrameAgreements
                downloadFile={this.downloadFile}
                download={this.props.download}
                clientId={ clientId }
                contractInfoId={ info.id }
                lang={lang}
            />
            <BuildingsAndAgreements
                downloadFile={this.downloadFile}
                agreements={info.agreements}
                contractInfo={info}
                clientId={ clientId }
            />
        </div>;
    };

    render() {
        const {t, client, department} = this.props;
        const clientId = _.get(client, 'client.id');
        const departments = department ? department.departments : [];
        const lang = getLanguage(this.props.translations.language);
        const contractInfos = _.get(client, 'client.contractInfos') || [];

        return (
            <StandardPage
                withBreadcrumb
                loaderVisible={
                    this.props.agreement.loading || this.props.client.loading ||this.props.frameagreement.loading
                }
            >
                <BreadcrumbBar client = {client.client } actionUrl={ '/client/' + clientId + '/details' } linkName={ t('show_client_details') }/>
                <header style={{ overflow: 'hidden' }}>
                    <h1 style={ [commonStyles.pageTitle, { marginTop: '0.175em', marginRight: '1em' }] }><div style = { commonStyles.headerIcon } ><i className= { 'fa fa-user' } /></div>{ client.client.name }</h1>
                    <div style={ buttonLayout }>
                        <Button
                            href={ '/client/' + clientId + '/createContractInfo' }
                            onClick={ this.createContractInfo }
                        >
                            { t('Add new contract') }
                        </Button>
                    </div>
                </header>
                <div style={{ marginTop: measures.standard }}>
                    { contractInfos && contractInfos.length > 1 &&
                    <Accordion>
                        { contractInfos.map(info => {
                            const clientDepartmentId = info && info.departmentId;
                            const clientDepartment = _.find(departments, { id: clientDepartmentId });
                            const departmentName = clientDepartment && clientDepartment.name ? clientDepartment.name : '';
                            const title = departmentName ? t(departmentName) : t('Unknown department');
                            return <AccordionItem
                                titleComponent={ <AccordionTitle>{ title }</AccordionTitle> }
                                key={ info.id }
                            >
                                <AccordionContent>
                                    { this.renderAgreementAndBuildings(clientId, info, lang, t) }
                                </AccordionContent>
                            </AccordionItem>;
                        }) }
                    </Accordion> }
                    { contractInfos && contractInfos.length === 1 &&
                        this.renderAgreementAndBuildings(clientId, contractInfos[0], lang, t)
                    }
                </div>
                {this.state.printingDialog &&
                    <PrintingView
                        agreementId={ this.state.printAgreementType === 'agreement' && this.state.printAgreementId  }
                        frameAgreementId={ this.state.printAgreementType === 'frameagreement' && this.state.printAgreementId }
                        closeAction={ this.closePrintingDialog }
                        lang={ this.state.printoutLanguage }
                        printoutType={ this.state.printoutType }
                    />
                }
            </StandardPage>
        );
    }
}




function mapStateToProps(state) {
    return {
        countries: state.countries,
        client: state.client,
        building: state.building,
        agreement: state.agreement,
        frameagreement: state.frameagreement,
        profile: state.userProfile,
        download: state.download,
        department: state.department
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(ClientOverviewView)));